import { sample, keys } from "lodash";
import * as React from "react";
import { navigate } from "gatsby-link";
import { Link } from "gatsby";
import styled from "styled-components/macro";
import { ApiNameType, API_ADDITIONAL_METADATA } from "../utils/apis";

import { COLORS } from "../utils/colors";
import { sizes } from "../utils/sizes";
import { Button } from "./Button";
import APICta from "./APICta";
import { BigWhiteBook, BigWhiteLightning, BigWhiteLock } from "./icons";
import { SecurityModal } from "./SecurityModal";

const Description = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.darkGray};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10vh 8px 0 8px;
`;

const Title = styled.h1`
  color: ${COLORS.nearWhite};
  font-weight: 800;
  font-size: 68px;
  text-align: center;
  line-height: 76px;

  @media (max-width: ${sizes.small}px) {
    font-size: 44px;
    line-height: 42px;
  }
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 28px;
  color: ${COLORS.darkGray};
  max-width: 600px;

  @media (max-width: ${sizes.small}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ButtonLink = styled(Button)`
  span {
    text-decoration: underline;
    font-weight: 350;
    color: ${COLORS.darkGray};
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;

const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
`;

const WhyTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 44px;
  color: ${COLORS.nearWhite};
  font-weight: 800;
  margin-bottom: 8px;

  @media (max-width: ${sizes.small}px) {
    font-size: 30px;
    line-height: 32px;
  }
`;

export const WhyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
  padding: 0 16px;
  max-width: 700px;
`;

const RelatedAPIsContainer = styled.div`
  display: grid;
  column-gap: 44px;
  row-gap: 44px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${sizes.mediumLarge}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${sizes.small}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const WhyDescription = styled(Description)`
  max-width: 500px;
  margin-bottom: 34px;
`;
export function HomePageContent({ onCtaClick }: { onCtaClick: () => void }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const onFeelingLucky = () => {
    console.log("sample");
    const apiName = sample(
      keys(API_ADDITIONAL_METADATA) as ApiNameType[]
    ) as ApiNameType;

    const operationId = sample(
      API_ADDITIONAL_METADATA[apiName].popularOperationIds
    );

    console.log("done ", `/${apiName}/api/${operationId}`);
    navigate(`/${apiName}/api/${operationId}`);
  };

  const allApis = keys(API_ADDITIONAL_METADATA) as ApiNameType[];
  return (
    <div style={{ background: COLORS.backgroundDarkGray, marginTop: 44 }}>
      <Wrapper>
        <Container>
          <Title>
            Easily explore
            <br />
            popular APIs
          </Title>
          <Subtitle>
            Explore 3rd party APIs like Github, Slack and Google from your
            browser. No signing up, no cost.
          </Subtitle>
          <CTAContainer>
            <ButtonLink
              type="link"
              hideshadow
              onClick={async () => {
                onFeelingLucky();
              }}
            >
              I'm feeling lucky
            </ButtonLink>
            <Button
              size="large"
              style={{ marginLeft: 24 }}
              type="primary"
              onClick={async () => {
                onCtaClick();
              }}
            >
              Choose an API
            </Button>
          </CTAContainer>
        </Container>
      </Wrapper>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div id="why" />
        <WhyContainer>
          <WhyTitle>
            <BigWhiteLightning />
            Click to auth
          </WhyTitle>
          <WhyDescription>
            Query APIs that require authentication without generating tokens
            yourself. Just click once to authorize tryapis.com for the service
            you need.
          </WhyDescription>
          <WhyTitle>
            <BigWhiteBook />
            In-line docs
          </WhyTitle>
          <WhyDescription>
            No more digging through API docs to find which query parameters do
            what. tryapis.com pre-fills endpoint parameters with in-line docs
            and hints.
          </WhyDescription>

          <WhyTitle>
            <BigWhiteLock />
            Data stays with you
          </WhyTitle>
          <WhyDescription>
            tryapis.com never stores your API requests or responses. All data is
            persisted locally on your browser.{" "}
            <a
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Learn more.
            </a>
            <SecurityModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </WhyDescription>
        </WhyContainer>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 84 }}>
        <RelatedAPIsContainer style={{ marginBottom: 64 }}>
          {allApis.map((api) => (
            <APICta api={api} key={api} style={{ width: 200 }} />
          ))}
        </RelatedAPIsContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: 44,
        }}
      >
        <a
          onClick={() => {
            setModalOpen(true);
          }}
          style={{
            marginRight: 12,
            color: COLORS.brightDarkGrayBorder,
          }}
        >
          Security
        </a>
        <Link
          style={{ marginRight: 12, color: COLORS.brightDarkGrayBorder }}
          to="/privacy"
        >
          Privacy
        </Link>
        <Link style={{ color: COLORS.brightDarkGrayBorder }} to="/terms">
          Terms
        </Link>
      </div>
    </div>
  );
}
