import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useKBar } from "kbar";

import styled from "styled-components";
import { HomePageContent } from "../components/HomePageContent";
import { NavBar } from "../components/NavBar";
import { HomePageSearch } from "../components/Search";
import {
  ApiMetadataType,
  ApiNameType,
  API_ADDITIONAL_METADATA,
} from "../utils/apis";
import { EndpointSummaryType } from "../utils/utils";
import { LayoutNoSearch } from "../components/Layout";

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 64px;
`;

type Props = {
  apiData: {
    [apiName: string]: {
      apiName: ApiNameType;
      apiMetadata: ApiMetadataType;
      apiEndpointsSummary: EndpointSummaryType[];
    };
  };
};

const Content = () => {
  const { query } = useKBar();

  const onCtaClick = () => {
    query?.toggle();
  };

  return (
    <>
      <NavBar
        cta="Choose an API"
        onCtaClick={async () => {
          onCtaClick();
        }}
        secondaryCta="About"
        onSecondaryCtaClick={() => {
          const yOffset = -60 - 8; // nav height
          const element = document.getElementById("why");
          if (element) {
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
          }
        }}
      />
      <HomePageContent onCtaClick={onCtaClick} />
    </>
  );
};

const IndexPage = ({ pageContext }: { pageContext: Props }) => {
  const { apiData } = pageContext;

  return (
    <div>
      <LayoutNoSearch>
        <HomePageSearch apiData={apiData}>
          <Content />
        </HomePageSearch>
      </LayoutNoSearch>
    </div>
  );
};

export default IndexPage;
